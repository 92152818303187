// This must be the first line in src/index.js
import "react-app-polyfill/stable"

import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "./fonts/fonts.css"
import App from "./App"
import { ErrorBoundary } from "react-error-boundary"

if (process.env.REACT_APP_FAKE_API_ENABLED === "true") {
  ;(async () => {
    import("./service/FakeAPIService")
  })()
}

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
)
