import styled, { css } from "styled-components/macro"
import { VideoPlayer } from "../../component/VideoPlayer/VideoPlayer"
import { AppConfig } from "../../config/AppConfig"

export const reddit = css`
  box-shadow: inset 0 0 2px 0 #ff0000;
`

export const PTableGroupHeading = styled.div`
  position: absolute;
  left: 55px;
  top: 56px;
  width: 969px;
  height: 72px;
  background-image: url("${AppConfig.api.contentDir}/penztar/heading-pricelist.jpg") !important;

  & div {
    display: block;
    position: absolute;
    left: 699px;
    top: 12px;
    font-size: 2rem;
    font-family: font-semi-condensed;
  }
`

export const PTableGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  width: 970px;
  left: 55px;
  top: 145px;
`

export const PTableFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const StyledPriceTableByCruise = styled.div`
  background-color: rgba(0, 23, 54, 0.4);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  &:first-of-type {
    margin-right: 1rem;
  }
`

export const PTableHeader = styled.div`
  text-align: center;
  background-color: #001736;
  color: white;
  border-bottom: 1px solid white;
  height: 50px;
  font-size: 35px;
  text-transform: uppercase;
  font-family: font-bold;
  &.isDinner {
    background-color: #be6e87;
  }
`

export const EURORate = styled.div`
  font-size: 2rem;
  color: white;
`

export const TicketList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px 16px;
  &.isDinner {
    padding: 5px 8rem;
  }
`

export const Ticket = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  line-height: 30px;
  font-size: 22px;
  font-family: font-semi-condensed;
  &.isTypeAdult {
    & div {
      font-size: 30px;
      line-height: 40px;
    }
  }
  &.isTypeBPCard {
    margin-left: 20px;
    & div {
      color: rgba(255, 255, 255, 0.3);
    }
  }
`

export const TicketName = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const TicketPriceEUR = styled.div`
  text-align: right;
  white-space: nowrap;
  margin-left: 15px;
`

export const TicketPriceHUF = styled.div`
  width: 150px;
  text-align: right;
  white-space: nowrap;
`
export const PTableVideoPlayer = styled(VideoPlayer)`
  position: absolute;
  left: 55px;
  bottom: 55px;
  overflow: hidden;
  height: 478px;
  border: 1px solid white;
  & > * {
    margin-top: -39px;
  }
`
