import React, { FC } from "react"
import * as S from "./styles"

type Props = {
  size?: number
  lineWeight?: number
  color?: string
  direction?: "left" | "right" | "up" | "down"
}

export const AnimatedArrow: FC<Props> = ({ size = 8, lineWeight = 5, color = "#69a3d3", direction = 'right' }) => {
  return (
    <S.StyledSpan
      style={{
        padding: `${size}px`,
        border: `solid ${color}`,
        borderWidth: `0 ${lineWeight}px ${lineWeight}px 0`,
      }}
    />
  )
}
