import styled, { keyframes } from "styled-components"

export const AnimVideoWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
`

export const AnimWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const fadeInOut = keyframes`
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`

export const InfinitPhotoSlider = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;
  height: 280px;

  & img {
    position: absolute;
    animation-name: ${fadeInOut};
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 30s;

    &:nth-of-type(1) {
      animation-delay: 20s;
    }

    &:nth-of-type(2) {
      animation-delay: 10s;
    }

    &:nth-of-type(3) {
      animation-delay: 0s;
    }
  }
`

export const ArrowsWrapper = styled.div`
  position: relative;
`

export const GateText = styled.div`  
position: absolute;
    display: inline-block;
    background-color: #69a3d3;
    height: 48px;
    padding: 0px 10px;
    color: black;
    font-family: "font-bold";
    font-size: 2.2rem;
  
`
export const Gate1Text = styled(GateText)`
  top: 370px;
  left: 180px;
  z-index: 700;
  `

export const Gate2Text = styled(GateText)`
  top: 140px;
  left: 180px;
  z-index: 700;
`


export const VideoWrapper = styled.div`
  background-color: white;
  padding-top: 1px;
  width: 1920px;
  overflow: hidden;

  & div {
    width: 1920px;
    margin-top: -1px;
  }
`
