import React from "react"
import { ScreenRegistrationResponse } from "@hmedia/legenda-ds-api-client"
import produce, { enableES5 } from "immer"
import { createContext, useContext, useReducer } from "react"
import { actionCreator, IAsyncState, isActionType } from "../utils"

enableES5()
interface ApplicationContext {
  serverConfig: IAsyncState<ScreenRegistrationResponse>
}

export const registerDisplayRequest = actionCreator("@display/REGISTER_REQUEST"),
  registerDisplaySuccess = actionCreator<ScreenRegistrationResponse>("@display/REGISTER_SUCCESS"),
  registerDisplayFailure = actionCreator<{ error: string }>("@display/REGISTER_FAILURE")

export const applicationContextActions = {
  registerDisplayRequest: registerDisplayRequest,
  registerDisplaySuccess: registerDisplaySuccess,
  registerDisplayFailure: registerDisplayFailure,
}

const initialAppContext: ApplicationContext = { serverConfig: { loading: false } }

const ApplicationStateContext = createContext(null)
const ApplicationDispatchContext = createContext(null)

/**
 * Application reducer
 * @param context
 * @param action
 * @returns
 */
function reducer(context: ApplicationContext, action) {
  if (isActionType(action, registerDisplayRequest)) {
    return produce(context, (draft) => {
      draft.serverConfig.loading = true
    })
  } else if (isActionType(action, registerDisplaySuccess)) {
    return produce(context, (draft) => {
      draft.serverConfig.data = action.payload
      draft.serverConfig.loading = false
    })
  } else if (isActionType(action, registerDisplayFailure)) {
    return produce(context, (draft) => {
      draft.serverConfig.data = undefined
      draft.serverConfig.error = action.payload.error
      draft.serverConfig.loading = false
    })
  }
}

/**
 * Application context provider
 * @param param0
 * @returns
 */
export function AppLicationContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialAppContext)

  return (
    <ApplicationStateContext.Provider value={state}>
      <ApplicationDispatchContext.Provider value={dispatch}>{children}</ApplicationDispatchContext.Provider>
    </ApplicationStateContext.Provider>
  )
}

export function useApplicationState() {
  const context = useContext<ApplicationContext>(ApplicationStateContext)
  if (context === undefined) {
    throw new Error("useApplicationState must be used within an AppStateContextProvider")
  }
  return context
}

export function useApplicationDispatch() {
  const context = useContext(ApplicationDispatchContext)
  if (context === undefined) {
    throw new Error("useApplicationDispatch must be used within an AppStateContextProvider")
  }
  return context
}
