import React, { FC, ReactNode, useCallback, useEffect, useState } from "react"
import Icon from "@ant-design/icons"
import { dayIcons } from "./day-icons"
import { nightIcons } from "./night-icons"

type Props = {
  code: string
  size?: number
  day?: boolean
}

export const WeatherIcon: FC<Props> = ({ code, day, size = 150, ...props }) => {
  
  return <Icon component={(day ? dayIcons : nightIcons)?.[code]} style={{ fontSize: `${size}px` }} />
}
