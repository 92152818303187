import { Navigator } from "react-router-dom";


export enum RemoteKey {
  'ArrowLeft' = 'ArrowLeft',
  'MediaPlayPause' = 'MediaPlayPause',
  'Menu' = 'Menu',
  'ArrowUp' = 'ArrowUp',
  'MediaRewind' = 'MediaRewind',
  'Tools' = 'Tools',
  'ArrowRight' = 'ArrowRight',
  'MediaFastForward' = 'MediaFastForward',
  'Info' = 'Info',
  'ArrowDown' = 'ArrowDown',
  'MediaPlay' = 'MediaPlay',
  'Source' = 'Source',
  'Enter' = 'Enter',
  'MediaPause' = 'MediaPause',
  'Exit' = 'Exit',
  'Back' = 'Back',
  'MediaStop' = 'MediaStop',
  'MediaRecord' = 'MediaRecord',
  'Caption' = 'Caption',
  'VolumeUp' = 'VolumeUp',
  'MediaTrackPrevious' = 'MediaTrackPrevious',
  'VolumeDown' = 'VolumeDown',
  'MediaTrackNext' = 'MediaTrackNext',
  '3D' = '3D',
  'VolumeMute' = 'VolumeMute',
  'Extra' = 'Extra',
  'ChannelUp' = 'ChannelUp',
  'PictureSize' = 'PictureSize',
  'ChannelDown' = 'ChannelDown',
  'Soccer' = 'Soccer',
  'ChannelList' = 'ChannelList',
  'Teletext' = 'Teletext',
  'MTS' = 'MTS',
  'ColorF0Red' = 'ColorF0Red',
  'Search' = 'Search',
  'ColorF1Green' = 'ColorF1Green',
  'Guide' = 'Guide',
  'ColorF2Yellow' = 'ColorF2Yellow',
  'ColorF3Blue' = 'ColorF3Blue',
  'Num0' = '0',
  'Num1' = '1',
  'Num2' = '2',
  'Num3' = '3',
  'Num4' = '4',
  'Num5' = '5',
  'Num6' = '6',
  'Num7' = '7',
  'Num8' = '8',
  'Num9' = '9',
  'Minus' = 'Minus',
  'PreviousChannel' = 'PreviousChannel'
}



type Registration = {
  [key in RemoteKey]?: (key: RemoteKey) => void;
};

export const registerRemoteKeysAndHandler: (((registrationMap: Registration) => ((event) => void) | undefined)) = (registrationMap: Registration) => {
  if (window.tizen !== undefined) {
    let tizen = window.tizen

    // unregister for standard exit key action
    tizen.tvinputdevice.unregisterKey("Exit")

    Object.keys(registrationMap).map((remoteKey) => {
      tizen.tvinputdevice.registerKey(remoteKey)
    })

    return (event) => {
      for (const remKey of Object.values(RemoteKey)) {
        if (tizen.tvinputdevice.getKey(remKey)?.code === event.keyCode) {
          registrationMap[remKey]?.(remKey)
        }
      }
    }
  } else return undefined
}
