import "../../fonts/fonts.css"
import React, { FC } from 'react'
import { AppConfig } from '../../config/AppConfig'
import './styles.css'
import styl from './styles.module.css'

interface Props {
    title?: string
    html?: string
}

export const InfoScreen: FC<Props> = ({
    title,
    html,
    ...props
}) => {
    const bgUrl = `${AppConfig.api.contentDir}/penztar/penztar-bg-empty.jpg`

    return (
      <div className={styl.infoScreenContainer} style={{ backgroundImage: `url(${bgUrl})` }}>
        {title && <div className={styl.infoScreenTitle}>{title}</div>}
        <div className={styl.infoScreenBody} dangerouslySetInnerHTML={{ __html: html ? html : '' }} />
      </div>
    )
}


