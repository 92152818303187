import React from "react"
import { ReactComponent as sunny } from "./day/sunny.svg"
import { ReactComponent as partlyCloudy } from "./day/partly-cloudy.svg"
import { ReactComponent as cloudy } from "./day/cloudy.svg"
import { ReactComponent as overcast } from "./day/overcast.svg"
import { ReactComponent as mist } from "./day/mist.svg"
import { ReactComponent as rain } from "./day/rain.svg"
import { ReactComponent as snow } from "./day/snow.svg"
import { ReactComponent as sleet } from "./day/sleet.svg"
import { ReactComponent as thunderstormsDayRain } from "./day/thunderstorms-day-rain.svg"
import { ReactComponent as thunderstormsDaySnow } from "./day/thunderstorms-day-snow.svg"
import { ReactComponent as fog } from "./day/fog-day.svg"
import { ReactComponent as drizzle } from "./day/partly-cloudy-day-drizzle.svg"
import { ReactComponent as thunderstorms } from "./day/thunderstorms.svg"
import { ReactComponent as hail } from "./day/hail.svg"

export const dayIcons = {
  "1000": sunny,
  "1003": partlyCloudy,
  "1006": cloudy,
  "1009": overcast,
  "1030": mist,
  "1063": rain,
  "1066": snow,
  "1069": snow,
  "1072": drizzle,
  "1087": thunderstorms,
  "1114": snow,
  "1117": snow,
  "1135": fog,
  "1147": partlyCloudy,
  "1150": drizzle,
  "1153": drizzle,
  "1168": drizzle,
  "1171": drizzle,
  "1180": rain,
  "1183": rain,
  "1186": rain,
  "1189": rain,
  "1192": rain,
  "1195": rain,
  "1198": rain,
  "1201": rain,
  "1204": sleet,
  "1207": sleet,
  "1210": snow,
  "1213": snow,
  "1216": snow,
  "1219": snow,
  "1222": snow,
  "1225": snow,
  "1237": snow,
  "1240": rain,
  "1243": rain,
  "1246": rain,
  "1249": sleet,
  "1252": sleet,
  "1255": snow,
  "1258": snow,
  "1261": hail,
  "1264": hail,
  "1273": thunderstormsDayRain,
  "1276": thunderstormsDayRain,
  "1279": thunderstormsDaySnow,
  "1282": thunderstormsDaySnow,
}
