import styled, { css } from "styled-components"
import { VideoPlayer } from "../../component/VideoPlayer/VideoPlayer"

const videoBoxShadow = css`
  /* box-shadow: 1em 1em 2em 0.1em rgba(0, 0, 0, 0.3); */
`

export const VideoTerkepAnimacio = styled(VideoPlayer)`
  position: absolute;
  left: 670px;
  top: 394px;
  & video {
    ${videoBoxShadow}
  }
`

export const TerkepVideo = styled(VideoPlayer)`
  position: absolute;
  left: 327px;
  bottom: 35px;
  overflow: hidden;
  height: 392px;
  width: 714px;

  & > div {
    margin-top: -17px;
    margin-left: -17px;
  }
`
