import classNames from "classnames"
import { format } from "date-fns"
import React, { FC } from "react"
import "../../fonts/fonts.css"
import "./styles.css"
import styl from "./styles.module.css"

interface Props {
  title?: string
  html?: string
  isTodayShown?: boolean
}

export const ActualityBox: FC<Props> = ({ title, html, isTodayShown, ...props }) => {
  const todayDate = format(new Date(), "dd. MM. yyyy.")

  return (
    <div className={classNames(styl.actualityBoxContainer)}>
      {title && (
        <div className={styl.actualityBoxTitle}>
          {title}
          {isTodayShown && <span className={styl.dateOfToday}>{" - " + todayDate}</span>}
        </div>
      )}
      <div className={styl.actualityBoxBody} dangerouslySetInnerHTML={{ __html: html as string }} />
    </div>
  )
}
