import { DisplayApiFacade, NetworkInfo } from './DisplayApiFacade/DisplayApiFacade.d';
import { SystemInfoEthernetNetwork } from "tizen-common-web"

const tizen = window.tizen

const getCapability = (id) => tizen?.systeminfo.getCapability('http://tizen.org/' + id)

let networkInfo: NetworkInfo = { ip: '1.2.4.6', macAddress: '11:22:33:44:55:66' }

tizen?.systeminfo.getPropertyValue(
  "ETHERNET_NETWORK",
  (eth: SystemInfoEthernetNetwork): void => {
    networkInfo.ip = eth.ipAddress
    networkInfo.macAddress = eth.macAddress
  },
  (error) => { }
)

export const DisplayApi: DisplayApiFacade = {

  deviceId: (): string => networkInfo.macAddress,

  /**
   * Privilege: http://tizen.org/privilege/tv.audio
   */
  getVolume: (): number => tizen?.tvaudiocontrol.getVolume() | -1,

  modelName: tizen ? `${getCapability('system/manufacturer')} ${getCapability('system/model_name')}` : 'SAMSUNG DEV TV',

  /**
   * Privilege: http://developer.samsung.com/privilege/network.public
   */
  getNetworkInfo: (): NetworkInfo => networkInfo,

  width: tizen ? getCapability("feature/screen.width") : 9999,

  height: tizen ? getCapability("feature/screen.height") : 9999,

  registerInputKey: tizen?.tvinputdevice.registerKey,

  unregisterInputKey: tizen?.tvinputdevice.unregisterKey,

  useragent: navigator?.userAgent,

  exit: tizen?.application.getCurrentApplication().exit || function () { throw new Error('No window.tizen declared!') }

}