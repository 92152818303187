import { DepartureModel } from "@hmedia/legenda-ds-api-client"
import React, { useEffect, useState } from "react"
import { AppConfig } from "../../config/AppConfig"
import { useApplicationState } from "../../context/AppContext"
import styl from "./style.module.css"

export type Cruise = {
  time: string
  name: string
  extraInfo?: string
}

const BeszallohidView = () => {
  const state = useApplicationState()

  const bgImg = `${AppConfig.api.contentDir}/beszallohid/beszallohid-bg.jpg`
  const [nextDepartures1, setnextDepartures1] = useState<DepartureModel>()
  const [nextDepartures2, setnextDepartures2] = useState<DepartureModel>()

  useEffect(() => {
    const nextDepartures = state.serverConfig.data?.content?.beszallohidInstance?.nextDepartures
    setnextDepartures1(nextDepartures?.["GATE1"])
    setnextDepartures2(nextDepartures?.["GATE2"])

    return () => {}
  }, [
    state.serverConfig.data?.serverTime,
    state.serverConfig.data?.weatherDataDTO,
    state.serverConfig.data?.content?.beszallohidInstance,
  ])

  return (
    <div className={styl.mainWrapper} style={{ backgroundImage: `url(${bgImg})` }}>
      <div className={styl.titleNextDep}>NEXT DEPARTURE / KÖVETKEZŐ JÁRAINDULÁSOK:</div>

      <div className={styl.nextWrapper}>
        <div className={styl.next1}>
          <div className={styl.time}>{(nextDepartures1?.startAt as string)?.slice(0, 5)}</div>
          <div className={styl.name}>{nextDepartures1?.cruise?.displayNameMap?.["en"]}</div>
          <div className={styl.extraInfo}>{nextDepartures1?.description || nextDepartures1?.cruise?.description}</div>
          <div className={styl.gate1box}>GATE 1</div>
        </div>
        <div className={styl.verticalLine}></div>
        <div className={styl.next2}>
          <div className={styl.time}>{(nextDepartures2?.startAt as string)?.slice(0, 5)}</div>
          <div className={styl.name}>{nextDepartures2?.cruise?.displayNameMap?.["en"]}</div>
          <div className={styl.extraInfo}>{nextDepartures2?.description || nextDepartures2?.cruise?.description}</div>
          <div className={styl.gate2box}>GATE 2</div>
        </div>
      </div>
    </div>
  )
}

export default BeszallohidView
