import { MetaGateEnum, UtasVaroV5 } from "@hmedia/legenda-ds-api-client"
import classNames from "classnames"
import { format, parseISO } from "date-fns"
import React, { FC } from "react"
import { Celsius } from "../../component/Celsius/Celsius"
import { CurrentTime } from "../../component/CurrentTime/CurrentTime"
import { WeatherIcon } from "../../component/Weather/WeatherIcon"
import { AppConfig } from "../../config/AppConfig"
import { useApplicationState } from "../../context/AppContext"
import { departureToCruiseConverter } from "../../utils"
import { formatCelsius } from "../../utils/utility"
import styl from "./style.module.css"
import * as S from "./styles"

export type Cruise = {
  time: string
  name: string
  isNext: boolean
}

type Props = {
  utasvaroV6?: UtasVaroV5
}

const UtasVaroKulso_V6View: FC<Props> = ({ utasvaroV6, ...props }) => {
  const state = useApplicationState()
  const videoURL = `${AppConfig.api.contentDir}/belsovaro/Belsovaro_V5.mp4`
  const meta = utasvaroV6?.meta
  const nextDepartures = utasvaroV6?.nextDepartures?.[meta?.gate]
  const nextDeparture = nextDepartures?.[0]
  const cruises: Cruise[] = departureToCruiseConverter(nextDepartures?.slice(1, 3))
  const serverDateTime = utasvaroV6?.serverDateTime
  const isGate1 = meta?.gate === MetaGateEnum.GATE1
  const gateName = isGate1 ? "GATE 1" : "GATE 2"
  const { temp, isDay, iconCode } = state.serverConfig.data?.weatherDataDTO || {}
  const celsius = formatCelsius(temp)

  const formatDate = (value: string) => {
    return format(parseISO(value), "dd. MM. yyyy.")
  }

  return (
    <S.MainWrapper>
      <div className={styl.infobar}>
        <S.GateDateContainer>
          <div className={styl.dateTimePanel}>
            <div className={styl.time}>
              <CurrentTime />
            </div>
            <div className={styl.date}>
              <div className={styl.dateYMD}>{serverDateTime && formatDate(serverDateTime)}</div>
            </div>
          </div>
        </S.GateDateContainer>
        <S.GateBox>{gateName}</S.GateBox>

        <div className={styl.weatherPanel}>
          <div className={styl.weatherIcon}>
            <WeatherIcon code={iconCode} day={isDay} size={170} />
          </div>
          <div className={styl.weatherCelsius}>
            {celsius}
            <sup>
              <Celsius />
            </sup>
          </div>
        </div>
      </div>

      <div className={styl.titleNextDep}>
        <strong>NEXT</strong> DEPARTURE / <strong>KÖVETKEZŐ</strong> INDULÁS:
      </div>

      <div className={styl.nextWrapper}>
        <div className={styl.next}>
          <div className={styl.startTime}>{(nextDeparture?.startAt as string)?.slice(0, 5)}</div>
          <div className={styl.name}>{nextDeparture?.cruise?.displayNameMap?.["en"]}</div>
          <div className={styl.extraInfo}>{nextDeparture?.description || nextDeparture?.cruise?.description}</div>
        </div>
      </div>

      <div className={styl.gateArrowsContainer}>
        <S.NextCruisesContainer>
          <S.LaterDeparturesTextWrapper>
            <S.LaterDeparturesText>
              <span>
                Later <i>departures</i>
              </span>
              <span>
                További <i>indulások</i>
              </span>
            </S.LaterDeparturesText>
          </S.LaterDeparturesTextWrapper>
          <S.VertLine />
          <S.LaterDepartureArrow />
          <S.NextCruiseListWrapper>
            {cruises?.map((cru) => (
              <S.NextCruiseRow className={classNames({ isNext: cru.isNext })}>
                <S.TimeNameWrapper className={classNames({ isNext: cru.isNext })}>
                  <S.CruiseStartTime>{cru.time}</S.CruiseStartTime>
                  <S.CruiseName>{cru.name}</S.CruiseName>
                </S.TimeNameWrapper>
              </S.NextCruiseRow>
            ))}
          </S.NextCruiseListWrapper>
        </S.NextCruisesContainer>
      </div>
    </S.MainWrapper>
  )
}

export default UtasVaroKulso_V6View
