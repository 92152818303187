import { ScreenRegistrationResponse, ScreenResourceControllerApi } from "@hmedia/legenda-ds-api-client";
import axios, { AxiosResponse } from 'axios';
import { AppConfig } from './../config/AppConfig';

const protocol = AppConfig.api.port === '443' ? "https" : "http"
const port = (AppConfig.api.port !== undefined && AppConfig.api.port !== '') ? `:${AppConfig.api.port}` : ''
const baseURL = `${protocol}://${AppConfig.api.host}${port}`;

const axiosInstance = axios.create({
  baseURL: baseURL,
  responseType: 'json'
})

interface DSApiService {
  registerScreen: (id: string, width: number, height: number, ipAddress: string, modelName: string) => Promise<AxiosResponse<ScreenRegistrationResponse>>
}

const screenApi = new ScreenResourceControllerApi(undefined, '', axiosInstance)

const _registerScreen = async (id: string, width: number, height: number, ipAddress: string, modelName: string) => await screenApi.registerScreen({
  id: id,
  width: width,
  height: height,
  ipAddress: ipAddress,
  modelName: modelName
})

const API: DSApiService = {
  registerScreen: _registerScreen
}

export default API;
