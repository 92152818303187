var isProduction = process.env.NODE_ENV === "production"

const version = process.env.REACT_APP_VERSION || 0
const host = process.env.REACT_APP_API_HOST || 'localhost'
const port = process.env.REACT_APP_API_PORT || 8080

const protocol = port === '443' ? "https" : "http"
const portPostfix = (port !== undefined && port !== '') ? `:${port}` : ''
const baseURL = `${protocol}://${host}${portPostfix}`;

const contentDir = `${baseURL}/static/content`

export const AppConfig = {
  version: version,
  isProduction: isProduction,
  api: {
    host: host,
    port: port,
    baseurl: baseURL,
    contentDir: contentDir
  }
}