import React from 'react'
import styled, { keyframes } from "styled-components";


const bounceRight = keyframes`
  0% {
    transform: translateX(0) rotate(-45deg);
  }
  50% {
    transform: translateX(-10px) rotate(-45deg);
  }
  100% {
    transform: translateX(0) rotate(-45deg);
  }
`

export const StyledSpan = styled.span`
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${bounceRight};
  animation-timing-function: linear;

  display: inline-block;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 0;
`;
