import { MetaGateEnum, UtasVaroV5 } from "@hmedia/legenda-ds-api-client"
import { format, parseISO } from "date-fns"
import React, { FC } from "react"
import { Celsius } from "../../component/Celsius/Celsius"
import { CurrentTime } from "../../component/CurrentTime/CurrentTime"
import { WeatherIcon } from "../../component/Weather/WeatherIcon"
import { useApplicationState } from "../../context/AppContext"
import { formatCelsius } from "../../utils/utility"
import styl from "./style.module.css"
import * as S from "./styles"

export type Cruise = {
  time: string
  name: string
  isNext: boolean
}

type Props = {
  utasvaroV7?: UtasVaroV5
}

const UtasVaroKulso_V7View: FC<Props> = ({ utasvaroV7, ...props }) => {
  const state = useApplicationState()
  const meta = utasvaroV7?.meta
  const nextDeparture = utasvaroV7?.nextDepartures?.[meta?.gate]?.[0]
  const serverDateTime = utasvaroV7?.serverDateTime
  const isGate1 = meta?.gate === MetaGateEnum.GATE1
  const gateName = isGate1 ? "GATE 1" : "GATE 2"
  const { temp, isDay, iconCode } = state.serverConfig.data?.weatherDataDTO || {}
  const celsius = formatCelsius(temp)

  const formatDate = (value: string) => {
    return format(parseISO(value), "dd. MM. yyyy.")
  }

  return (
    <S.MainWrapper>
      <div className={styl.infobar}>
        <S.GateDateContainer>
          <div className={styl.dateTimePanel}>
            <div className={styl.time}>
              <CurrentTime />
            </div>
            <div className={styl.date}>
              <div className={styl.dateYMD}>{serverDateTime && formatDate(serverDateTime)}</div>
            </div>
          </div>
        </S.GateDateContainer>
        <S.GateBox>{gateName}</S.GateBox>

        <div className={styl.weatherPanel}>
          <div className={styl.weatherIcon}>
            <WeatherIcon code={iconCode} day={isDay} size={170} />
          </div>
          <div className={styl.weatherCelsius}>
            {celsius}
            <sup>
              <Celsius />
            </sup>
          </div>
        </div>
      </div>

      <div className={styl.titleNextDep}>NEXT DEPARTURE / KÖVETKEZŐ INDULÁS:</div>

      <div className={styl.nextWrapper}>
        <div className={styl.next}>
          <div className={styl.startTime}>{(nextDeparture?.startAt as string)?.slice(0, 5)}</div>
          <div className={styl.name}>{nextDeparture?.cruise?.displayNameMap?.["en"]}</div>
          <div className={styl.extraInfo}>{nextDeparture?.description || nextDeparture?.cruise?.description}</div>
        </div>
      </div>
    </S.MainWrapper>
  )
}

export default UtasVaroKulso_V7View
