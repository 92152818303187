import styled from "@emotion/styled"
import { RunningContentContentTypeEnum } from "@hmedia/legenda-ds-api-client"
import React, { useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
// import { fakeDisplayApiService as displayApi } from "./service/FakeDisplayApiService"
import { BrowserRouter, Link, Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import { useInterval } from "usehooks-ts"
import { ErrorFallback } from "."
import "./App.css"
import BeszallohidView from "./contents/Beszallohid/BeszallohidView"
import FelsoTeraszView from "./contents/FelsoTerasz/FelsoTeraszView"
import KulsoVaroView from "./contents/KulsoVaro/KulsoVaroView"
import KulsoVaro_V2View from "./contents/KulsoVaro_V2/KulsoVaro_V2View"
import MediaFileView from "./contents/MediaFile/MediaFileView"
import PenztarView from "./contents/Penztar/PenztarView"
import RakpartPromoView from "./contents/RakpartPromo/RakpartPromoView"
import SplashView from "./contents/Splash/SplashView"
import UtasVaroView from "./contents/UtasVaro/UtasVaroView"
import BarView from "./contents/Bar/BarView"
import {
  applicationContextActions,
  AppLicationContextProvider,
  useApplicationDispatch,
  useApplicationState,
} from "./context/AppContext"
import { DisplayApi } from "./service/DisplayApiService"
import API from "./service/DSApiService"
import { AppConfig } from "./config/AppConfig"
import IconTestView from "./component/Weather/IconTestView"
import { registerRemoteKeysAndHandler, RemoteKey } from "./utils/remoteKeyManager"
import BeszallohidDock8View from "./contents/Beszallohid_DOCK8/BeszallohidDock8View"
import UtasVaroKulso_V5View from "./contents/UtasVaroKulso_V5/UtasVaroKulso_V5View"
import UtasVaroKulso_V6View from "./contents/UtasVaroKulso_V6/UtasVaroKulso_V6View"
import UtasVaroKulso_V7View from "./contents/UtasVaroKulso_V7/UtasVaroKulso_V7View"
import RakpartPromo_V2View from "./contents/RakpartPromo_V2/RakpartPromo_V2View"
import RakpartPromo_V3View from "./contents/RakpartPromo_V3/RakpartPromo_V3View"
import RakpartPromo_V4View from "./contents/RakpartPromo_V4/RakpartPromo_V4View"
import Penztar_V2View from "./contents/Penztar_V2/Penztar_V2View"
import { InfoScreen } from "./component/InfoScreen/InfoScreen"
import RakpartPromo_V5View from "./contents/RakpartPromo_V5/RakpartPromo_V5View"
import RakpartPromo_V6View from "./contents/RakpartPromo_V6/RakpartPromo_V6View"
import Penztar_V3View from "./contents/Penztar_V3/Penztar_V3View"
import Beszallohid_V2View from "./contents/Beszallohid_V2/Beszallohid_V2View"

function App() {
  const state = useApplicationState()
  const [customMacAddress, setCustomMacAddress] = useState<string>("")
  const dispatch = useApplicationDispatch()
  const displayApi = DisplayApi
  const navi = useNavigate()
  const [key, setKey] = useState<string | number>("")

  const [searchParams, setsearchParams] = useSearchParams()
  const macParam = searchParams.get("mac")
  if (customMacAddress === "" && macParam !== null) setCustomMacAddress(macParam)

  useInterval(
    () => {
      const deviceId = customMacAddress || displayApi?.deviceId()
      dispatch(applicationContextActions.registerDisplayRequest)
      API.registerScreen(
        deviceId,
        displayApi?.width,
        displayApi?.height,
        displayApi?.getNetworkInfo().ip,
        displayApi?.modelName
      )
        .then((value) => {
          let disp = dispatch(applicationContextActions.registerDisplaySuccess(value.data))

          if (value.data?.content?.contentType === RunningContentContentTypeEnum.SPLASH) {
            navi("/")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.MEDIAFILE) {
            navi("/mediafile")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.RAKPART_PROMO) {
            navi("/rakpart-promo")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.RAKPART_PROMO_V2) {
            navi("/rakpart-promo-v2")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.RAKPART_PROMO_V3) {
            navi("/rakpart-promo-v3")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.RAKPART_PROMO_V4) {
            navi("/rakpart-promo-v4")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.RAKPART_PROMO_V5) {
            navi("/rakpart-promo-v5")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.RAKPART_PROMO_V6) {
            navi("/rakpart-promo-v6")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.BESZALLO_HID1) {
            navi("/beszallohid")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.BESZALLO_HID1_V2) {
            navi("/beszallohid-v2")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.PENZTAR) {
            navi("/penztar")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.PENZTAR_V2) {
            navi("/penztar-v2")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.PENZTAR_V3) {
            navi("/penztar-v3")
          } else if (
            [RunningContentContentTypeEnum.KULSO_VARO1, RunningContentContentTypeEnum.KULSO_VARO2].includes(
              value.data?.content?.contentType
            )
          ) {
            navi("/kulso-varo")
          } else if (
            [RunningContentContentTypeEnum.KULSO_VARO1_V2, RunningContentContentTypeEnum.KULSO_VARO2_V2].includes(
              value.data?.content?.contentType
            )
          ) {
            navi("/kulso-varo-v2")
          } else if (
            [RunningContentContentTypeEnum.UTAS_VARO1_V5, RunningContentContentTypeEnum.UTAS_VARO2_V5].includes(
              value.data?.content?.contentType
            )
          ) {
            navi("/utas-varo-kulso-v5")
          } else if (
            [RunningContentContentTypeEnum.UTAS_VARO1_V6, RunningContentContentTypeEnum.UTAS_VARO2_V6].includes(
              value.data?.content?.contentType
            )
          ) {
            navi("/utas-varo-kulso-v6")
          } else if (
            [RunningContentContentTypeEnum.UTAS_VARO1_V7, RunningContentContentTypeEnum.UTAS_VARO2_V7].includes(
              value.data?.content?.contentType
            )
          ) {
            navi("/utas-varo-kulso-v7")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.FELSO_TERASZ) {
            navi("/felso-terasz")
          } else if (value.data?.content?.contentType === RunningContentContentTypeEnum.BAR) {
            navi("/bar")
          } else if (
            [RunningContentContentTypeEnum.UTAS_VARO1, RunningContentContentTypeEnum.UTAS_VARO2].includes(
              value.data?.content?.contentType
            )
          ) {
            navi("/belso-varo")
          } else {
            navi("/")
          }
          return disp
        })
        .catch((reason) => {
          dispatch(applicationContextActions.registerDisplayFailure(reason))
          navi("/")
        })
    },
    AppConfig.isProduction ? 10000 : 2000
  )

  // Remote Handlers
  const handleKeyDown = registerRemoteKeysAndHandler({
    ColorF0Red: () => window.tizen?.application.getCurrentApplication().exit(),
    1: () => navi("/"),
  })

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, false)
    navi("/")
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <div style={{ overflow: "hidden" }}>
      {state?.serverConfig?.data?.info && (
        <InfoScreen
          title={state?.serverConfig?.data?.info?.title}
          html={state?.serverConfig?.data?.info?.contentHTML}
        />
      )}
      {/* <Navi /> */}
      <Routes>
        {/* <Route path={"/"} element={<IconTestView />} /> */}
        <Route path={"/"} element={<SplashView customMacAddress={customMacAddress} />} />
        <Route path={"/mediafile"} element={<MediaFileView />} />
        <Route
          path={"/rakpart-promo"}
          element={
            <RakpartPromoView departures={state?.serverConfig?.data?.content?.rakpartPromoInstance?.departures} />
          }
        />
        <Route
          path={"/rakpart-promo-v2"}
          element={
            <RakpartPromo_V2View departures={state?.serverConfig?.data?.content?.rakpartPromoInstance?.departures} />
          }
        />
        <Route
          path={"/rakpart-promo-v3"}
          element={
            <RakpartPromo_V3View departures={state?.serverConfig?.data?.content?.rakpartPromoInstance?.departures} />
          }
        />
        <Route
          path={"/rakpart-promo-v4"}
          element={
            <RakpartPromo_V4View departures={state?.serverConfig?.data?.content?.rakpartPromoInstance?.departures} />
          }
        />
        <Route
          path={"/rakpart-promo-v5"}
          element={
            <RakpartPromo_V5View
              departures={state?.serverConfig?.data?.content?.rakpartPromoInstance?.departures}
              ticketPrices={state?.serverConfig?.data?.content?.rakpartPromoInstance?.ticketPrices}
              exchangeRate={state?.serverConfig?.data?.content?.rakpartPromoInstance?.exchangeRate}
              actuality={state?.serverConfig?.data?.content?.rakpartPromoInstance?.actuality}
            />
          }
        />
        <Route
          path={"/rakpart-promo-v6"}
          element={
            <RakpartPromo_V6View
              departures={state?.serverConfig?.data?.content?.rakpartPromoInstance?.departures}
              ticketPrices={state?.serverConfig?.data?.content?.rakpartPromoInstance?.ticketPrices}
              exchangeRate={state?.serverConfig?.data?.content?.rakpartPromoInstance?.exchangeRate}
              actuality={state?.serverConfig?.data?.content?.rakpartPromoInstance?.actuality}
            />
          }
        />
        <Route path={"/beszallohid"} element={<BeszallohidView />} />
        <Route path={"/beszallohid-v2"} element={<Beszallohid_V2View />} />
        <Route
          path={"/penztar"}
          element={
            <PenztarView
              departures={state?.serverConfig?.data?.content?.penztarInstance?.departures}
              ticketPrices={state?.serverConfig?.data?.content?.penztarInstance?.ticketPrices}
              exchangeRate={state?.serverConfig?.data?.content?.penztarInstance?.exchangeRate}
            />
          }
        />
        <Route
          path={"/penztar-v2"}
          element={
            <Penztar_V2View
              departures={state?.serverConfig?.data?.content?.penztarInstance?.departures}
              ticketPrices={state?.serverConfig?.data?.content?.penztarInstance?.ticketPrices}
              exchangeRate={state?.serverConfig?.data?.content?.penztarInstance?.exchangeRate}
              actuality={state?.serverConfig?.data?.content?.penztarInstance?.actuality}
            />
          }
        />
        <Route
          path={"/penztar-v3"}
          element={
            <Penztar_V3View
              departures={state?.serverConfig?.data?.content?.penztarInstance?.departures}
              ticketPrices={state?.serverConfig?.data?.content?.penztarInstance?.ticketPrices}
              exchangeRate={state?.serverConfig?.data?.content?.penztarInstance?.exchangeRate}
              actuality={state?.serverConfig?.data?.content?.penztarInstance?.actuality}
            />
          }
        />
        <Route
          path={"/kulso-varo"}
          element={<KulsoVaroView type={state?.serverConfig?.data?.content?.contentType} />}
        />
        <Route
          path={"/kulso-varo-v2"}
          element={<KulsoVaro_V2View kulsovaroV2={state?.serverConfig?.data?.content?.kulsoVaroV2Instance} />}
        />
        <Route
          path={"/utas-varo-kulso-v5"}
          element={<UtasVaroKulso_V5View utasvaroV5={state?.serverConfig?.data?.content?.utasVaroV5Instance} />}
        />
        <Route
          path={"/utas-varo-kulso-v6"}
          element={<UtasVaroKulso_V6View utasvaroV6={state?.serverConfig?.data?.content?.utasVaroV5Instance} />}
        />
        <Route
          path={"/utas-varo-kulso-v7"}
          element={<UtasVaroKulso_V7View utasvaroV7={state?.serverConfig?.data?.content?.utasVaroV5Instance} />}
        />
        <Route path={"/felso-terasz"} element={<BeszallohidDock8View />} />
        <Route path={"/bar"} element={<BarView />} />
        <Route
          path={"/belso-varo"}
          element={<UtasVaroView utasvaro={state?.serverConfig?.data?.content?.utasvaroInstance} />}
        />
      </Routes>
    </div>
  )
}
const AppWrapper = () => (
  <AppLicationContextProvider>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </AppLicationContextProvider>
)

export default AppWrapper

const NaviWrapper = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 10px;
  line-height: 10px;
  top: 2px;
  left: 2px;
  z-index: 999;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 3px;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  a,
  a:visited,
  a:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  a:not(:first-of-type) {
    margin-left: 5px;
  }
`

const Navi = () => {
  return (
    <NaviWrapper>
      <Link to={"/"}>/splash</Link>
      <Link to={"/mediafile"}>/mediafile</Link>
      <Link to={"/rakpart-promo"}>/rakpart-promo</Link>
      <Link to={"/rakpart-promo-v2"}>/rakpart-promo-v2</Link>
      <Link to={"/rakpart-promo-v3"}>/rakpart-promo-v3</Link>
      <Link to={"/beszallohid"}>/beszallohid</Link>
      <Link to={"/beszallohid-v2"}>/beszallohid-v2</Link>
      <Link to={"/penztar"}>/penztar</Link>
      <Link to={"/kulso-varo"}>/kulso-varo</Link>
      <Link to={"/felso-terasz"}>/felso-terasz</Link>
      <Link to={"/belso-varo"}>/kulso-varo</Link>
    </NaviWrapper>
  )
}
