import { DepartureModel } from "@hmedia/legenda-ds-api-client"
import React, { useEffect, useState } from "react"
import TimetableLayout, { Cruise } from "../../component/TimetableLayout/TimetableLayout"
import { AppConfig } from "../../config/AppConfig"
import { departureToCruiseConverter } from "../../utils"
import * as S from "./styles"

type Props = {
  departures?: DepartureModel[]
}
const RakpartPromoView: React.FC<Props> = ({ departures, ...props }) => {
  const [cruises, setCruises] = useState<Cruise[]>([])
  const videoTerkepAnim = `${AppConfig.api.contentDir}/rakpart-promo/rakpart-clip__terkep-animacio.mp4`

  useEffect(() => {
    const cruisess = departures ? departureToCruiseConverter(departures) : []

    setCruises(cruisess)

    return () => {}
  }, [departures])

  return (
    <TimetableLayout theme={{ size: "COMPACT" }} cruises={cruises}>
      <S.VideoTerkepAnimacio url={videoTerkepAnim} width={598} height={298} />
    </TimetableLayout>
  )
}

export default RakpartPromoView
