import React from "react"
import Clock from "react-live-clock"

interface Props {
  className?: string
  withSeconds?: boolean
}

export const CurrentTime: React.FC<Props> = ({ className, withSeconds = false, ...props }) => {
  return (
    <Clock
      format={(withSeconds && "HH:mm:ss") || "HH:mm"}
      ticking={true}
      timezone={"Europe/Budapest"}
      className={className}
    />
  )
}
