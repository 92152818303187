import { DepartureModel } from "@hmedia/legenda-ds-api-client"
import React, { useEffect, useState } from "react"
import TimetableLayout, { Cruise } from "../../component/TimetableLayout/TimetableLayout"
import { AppConfig } from "../../config/AppConfig"
import { departureToCruiseConverter } from "../../utils"
import * as S from "./styles"

type Props = {
  departures?: DepartureModel[]
}
const RakpartPromo_V4View: React.FC<Props> = ({ departures, ...props }) => {
  const [cruises, setCruises] = useState<Cruise[]>([])
  const videoBELLA = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__DUNABELLA.m4v`
  const videoLEGENDA = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__DUNAILEGENDA.m4v`
  const videoDINNER = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__DINNER.m4v`
  const videoTerkepAnim = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__terkep-animacio.m4v`

  useEffect(() => {
    const cruisess = departures ? departureToCruiseConverter(departures) : []

    setCruises(cruisess)

    return () => {}
  }, [departures])

  return (
    <TimetableLayout theme={{ size: "COMPACT_V4" }} cruises={cruises}>
      <S.VideoTerkepAnimacio url={videoTerkepAnim} width={598} height={298} />
    </TimetableLayout>
  )
}

export default RakpartPromo_V4View
