import { DepartureModel, MetaGateEnum, Utasvaro } from "@hmedia/legenda-ds-api-client"
import classNames from "classnames"
import { format, parseISO } from "date-fns"
import React, { FC } from "react"
import { AnimatedArrow } from "../../component/ArrowRight/AnimatedArrow"
import { Celsius } from "../../component/Celsius/Celsius"
import { CurrentTime } from "../../component/CurrentTime/CurrentTime"
import { VideoPlayer } from "../../component/VideoPlayer/VideoPlayer"
import { WeatherIcon } from "../../component/Weather/WeatherIcon"
import { AppConfig } from "../../config/AppConfig"
import { useApplicationState } from "../../context/AppContext"
import { departureToCruiseConverter } from "../../utils"
import { formatCelsius } from "../../utils/utility"
import styl from "./style.module.css"
import * as S from "./styles"

export type Cruise = {
  time: string
  name: string
  isNext: boolean
}

type Props = {
  utasvaro?: Utasvaro
}

const UtasVaroView: FC<Props> = ({ utasvaro, ...props }) => {
  const state = useApplicationState()
  const videoURL = `${AppConfig.api.contentDir}/belsovaro/Belsovaro_V5.mp4`
  const nextDepartures = utasvaro?.nextDepartures
  const meta = utasvaro?.meta
  const serverDateTime = utasvaro?.serverDateTime
  const cruises: Cruise[] = departureToCruiseConverter(nextDepartures?.[meta?.gate])
  const gateName = meta?.gate === MetaGateEnum.GATE1 ? "GATE 1" : "GATE 2"
  const { temp, isDay, iconCode } = state.serverConfig.data?.weatherDataDTO || {}
  const celsius = formatCelsius(temp)

  const formatDate = (value: string) => {
    return format(parseISO(value), "dd. MM. yyyy.")
  }
  const getDay = (value: string) => {
    return format(parseISO(value), "iiii")
  }

  return (
    <div className={styl.mainWrapper}>
      <div className={styl.infobar}>
        <S.GateDateContainer>
          <S.GateBox>{gateName}</S.GateBox>
          <div className={styl.dateTimePanel}>
            <div className={styl.time}>
              <CurrentTime />
            </div>
            <div className={styl.date}>
              <div className={styl.dateYMD}>{serverDateTime && formatDate(serverDateTime)}</div>
            </div>
          </div>
        </S.GateDateContainer>
        <S.NextCruiseContainer>
          {cruises?.map((cru) => (
            <S.NextCruiseRow className={classNames({ isNext: cru.isNext })}>
              {cru.isNext && (
                <S.StyledArrow>
                  <AnimatedArrow size={18} />
                </S.StyledArrow>
              )}

              <S.TimeNameWrapper className={classNames({ isNext: cru.isNext })}>
                <S.CruiseStartTime>{cru.time}</S.CruiseStartTime>
                <S.CruiseName>{cru.name}</S.CruiseName>
              </S.TimeNameWrapper>
            </S.NextCruiseRow>
          ))}
        </S.NextCruiseContainer>
        <div className={styl.weatherPanel}>
          <div className={styl.weatherIcon}>
            <WeatherIcon code={iconCode} day={isDay} />
          </div>
          <div className={styl.weatherCelsius}>
            {celsius}
            <sup>
              <Celsius />
            </sup>
          </div>
        </div>
      </div>
      <div className={styl.videoContainer}>
        <VideoPlayer url={videoURL} width={1920} />
      </div>
    </div>
  )
}

export default UtasVaroView
