import styled from "styled-components/macro"
import { AppConfig } from "../../config/AppConfig"

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  background-color: #0b101d;
  color: #ffffff;
  overflow: hidden;
  background-image: url("${AppConfig.api.contentDir}/penztar/penztar-bg-empty.jpg") !important;
`

export const GateDateContainer = styled.div`
  border-right: 1px solid white;
`

export const GateBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1050px;
  min-width: 1050px;

  font-size: 14rem;
  font-family: font-bold;
  line-height: 12rem;
  padding-top: 45px;
  background-color: #69a3d3;
  color: black;
  white-space: nowrap;
`

export const NextCruisesContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-left: 420px;
  padding-top: 0.8rem;
  font-family: font-bold;
  overflow: hidden;
  align-items: center;
`

export const VertLine = styled.div`
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  height: 6.5rem;
  background-color: rgba(255, 255, 255, 1);
`

export const CruiseStartTime = styled.div`
  margin-right: 1.5rem;
  color: #ffffff;
  font-family: font-semi-condensed-bold;
`

export const LaterDeparturesTextWrapper = styled.div`
  display: flex;
`

export const LaterDeparturesText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: right;
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-family: font-bold;
  text-transform: uppercase;
  white-space: nowrap;
  padding-right: 1rem;
  padding-top: 12px;
  color: rgba(255, 255, 255, 0.5);

  & i {
    font-style: normal;
    font-family: font-light;
    color: #69a3d3
  }
`

export const LaterDepartureArrow = styled.div`
  display: inline-block;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 0;
  padding: 20px;
  border: solid #69a3d3;
  border-width: 0 3px 3px 0;
  margin-right: 2rem;
`
export const NextCruiseListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
// ∘ U+2218
export const NextCruiseRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 4rem;
  line-height: 1;
  height: 3.7rem;
  margin-top: 0.5rem;

  &:nth-of-type(1n + 4) {
    display: none;
  }

  opacity: 1;
`
export const TimeNameWrapper = styled.div`
  display: flex;
`
export const StyledArrow = styled.div`
  margin-right: 1rem;
`

export const CruiseName = styled.div`
  font-family: font-semi-condensed;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 700px;
  white-space: nowrap;
  color: #69a3d3;
`
