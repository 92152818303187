import { DepartureModel } from "@hmedia/legenda-ds-api-client"
import React, { useEffect, useState } from "react"
import TimetableLayout, { Cruise } from "../../component/TimetableLayout/TimetableLayout"
import { AppConfig } from "../../config/AppConfig"
import { departureToCruiseConverter } from "../../utils"
import * as S from "./styles"
import styl from "./style.module.css"
import { ReactComponent as ArrowIcon } from "./dock8-arrow.svg"


type Props = {
  departures?: DepartureModel[]
}
const RakpartPromo_V3View: React.FC<Props> = ({ departures, ...props }) => {
  const [cruises, setCruises] = useState<Cruise[]>([])
  const videoBELLA = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__DUNABELLA.m4v`
  const videoLEGENDA = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__DUNAILEGENDA.m4v`
  const videoDINNER = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__DINNER.m4v`
  const videoTerkepAnim = `${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart-clip__terkep-animacio.m4v`

  useEffect(() => {
    const cruisess = departures ? departureToCruiseConverter(departures) : []

    setCruises(cruisess)

    return () => {}
  }, [departures])

  return (
    <TimetableLayout theme={{ size: "COMPACT_V3" }} cruises={cruises}>
      <S.VideoBella url={videoBELLA} width={282} height={136} />
      <S.VideoLegenda url={videoLEGENDA} width={282} height={160} />
      <S.VideoDinner url={videoDINNER} width={146} height={180} />
      <S.VideoTerkepAnimacio url={videoTerkepAnim} width={598} height={298} />
      <div className={styl.dock8Arrow}>
        <ArrowIcon />
      </div>
    </TimetableLayout>
  )
}

export default RakpartPromo_V3View
