import { default as classNames, default as cs } from "classnames"
import React, { FC, useEffect, useRef, useState } from "react"
import { ThemeProvider } from "styled-components/macro"
import { AnimatedArrow } from "../../component/ArrowRight/AnimatedArrow"
import * as S from "./styles"

export interface Cruise {
  time: string
  name: string
  extraInfo?: string
  isGone: boolean
  isNext: boolean
  isSoldOut: boolean
  isCancelled: boolean
  gate: string
}

interface Props {
  cruises?: Cruise[]
  theme?: {
    size: "NORMAL" | "COMPACT" | "COMPACT_V2" | "COMPACT_V3" | "COMPACT_V4"
    coloring?: "FADE_ON_PAST" | "NO_FADE_ON_PAST"
  }
}
const percentOf = (a: number | undefined, b: number | undefined) => a && b && a / b

const TimetableLayout: FC<Props> = ({ cruises, theme = { size: "NORMAL", coloring: "FADE_ON_PAST" }, ...props }) => {
  const [innerScaleW, setInnerScaleW] = useState<string>()
  const [innerScaleH, setInnerScaleH] = useState<number>(1)
  const outerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const isNoFadeOnPast= theme.coloring === "NO_FADE_ON_PAST"

  useEffect(() => {
    const outH = outerRef.current?.clientHeight
    const innH = innerRef.current?.clientHeight
    const ratioHPerc = percentOf(outH, innH) as number
    setInnerScaleH(ratioHPerc < 1 ? ratioHPerc : 1)

    return () => {}
  }, [cruises])

  useEffect(() => {
    const outBoundRect = outerRef.current?.getBoundingClientRect()
    const outW = outBoundRect?.width as number
    const innBoundRect = innerRef.current?.getBoundingClientRect()
    const innW = innBoundRect?.width as number
    const ratioW = innW + outW * (1 - innerScaleH) * (1 + innerScaleH * 1.2)

    // setInnerScaleW(innerScaleH < 1 ? `${ratioW}px` : "100%")

    return () => {}
  }, [innerScaleH])

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper
        className={classNames({
          themeCompact: ["COMPACT", "COMPACT_V2", "COMPACT_V3", "COMPACT_V4"].includes(theme.size),
          themeCompactV2: theme.size === "COMPACT_V2",
          themeCompactV3: theme.size === "COMPACT_V3",
          themeCompactV4: theme.size === "COMPACT_V4",
          themeNormal: theme.size === "NORMAL",
          coloringNoFadeOnPast: isNoFadeOnPast,
        })}
      >
        {props.children}

        <S.TodaysDepartureHeading />
        {theme.coloring !== "NO_FADE_ON_PAST" && <S.TodaysDepartureHeadingHU />}
        <S.RightSideContainer ref={outerRef}>
          <S.InnerContainer ref={innerRef} scale={innerScaleH}>
            {cruises?.map((cr, idx) => (
              <S.DepartureRow
                key={idx}
                className={cs({
                  isNext: cr.isNext,
                  isGone: !isNoFadeOnPast && cr.isGone,
                  isSoldOut: !cr.isGone && cr.isSoldOut,
                  isCancelled: !cr.isGone && cr.isCancelled,
                })}
              >
                <S.CruiseNameRow>
                  {cr.isNext && (
                    <S.StartingDepartureArrow>
                      <AnimatedArrow />
                    </S.StartingDepartureArrow>
                  )}
                  <S.StartTime>{cr.time}</S.StartTime>
                  <S.Name>{cr.name}</S.Name>
                  {(isNoFadeOnPast || (!cr.isGone && !cr.isCancelled)) && <S.GateName>{cr.gate}</S.GateName>}

                  {!cr.isGone && cr.isCancelled && (
                    <S.CancelledIconSVG
                      fillColor="#b4b8be"
                      width={theme.size !== "NORMAL" ? 170 : 247}
                      height={theme.size !== "NORMAL" ? 23 : 34}
                    />
                  )}
                  {!cr.isGone && !cr.isCancelled && cr.isSoldOut && (
                    <S.SoldOutIconSvg
                      fillColor="#ec6262"
                      width={theme.size !== "NORMAL" ? 150 : 230}
                      height={theme.size !== "NORMAL" ? 26 : 34}
                    />
                  )}
                </S.CruiseNameRow>
                {cr.extraInfo && (isNoFadeOnPast || (!cr.isGone && !cr.isCancelled)) && (
                  <S.ExtraInfo>{cr.extraInfo}</S.ExtraInfo>
                )}
              </S.DepartureRow>
            ))}
          </S.InnerContainer>
        </S.RightSideContainer>
      </S.Wrapper>
    </ThemeProvider>
  )
}

export default TimetableLayout
