import { DepartureModel, InformModel, PricetableItem, TicketPriceDTOTypeEnum } from "@hmedia/legenda-ds-api-client"
import classNames from "classnames"
import React, { FC, useEffect, useState } from "react"
import NumberFormat from "react-number-format"
import styled from "styled-components"
import { ActualityBox } from "../../component/ActualityBox/ActualityBox"
import TimetableLayout, { Cruise } from "../../component/TimetableLayout/TimetableLayout"
import { AppConfig } from "../../config/AppConfig"
import { departureToCruiseConverter } from "../../utils"
import * as S from "./styles"

export type PriceTableItem = {
  name: string | undefined
  cruiseName?: string | undefined
  internalCruiseId: string | undefined
  internalCruiseName: string | undefined
  priceEUR: string | undefined
  priceHUF: string | undefined
  index: number
  type: TicketPriceDTOTypeEnum | undefined
}

const converter = (origPrice?: PricetableItem): PriceTableItem[] | undefined =>
  origPrice?.prices?.map((pr) => ({
    name: pr.name,
    internalCruiseName: pr.cruise.internalName,
    internalCruiseId: pr.cruise.internalId,
    priceEUR: pr.priceEUR,
    priceHUF: pr.priceHUF,
    tags: pr.tags,
    index: pr.index,
    cruiseName: pr.cruise.displayNameMap["en"],
    type: pr.type,
  }))

type Props = {
  departures?: DepartureModel[]
  ticketPrices?: PricetableItem[]
  exchangeRate?: string
  actuality?: InformModel
}

const RakpartPromo_V5View: FC<Props> = ({ departures, ticketPrices, exchangeRate, actuality, ...props }) => {
  const vid2 = `${AppConfig.api.contentDir}/penztar/terkep-animacio.m4v`
  const hangulatVid = `${AppConfig.api.contentDir}/penztar/v2/Penztar_V2_hangulat.m4v`
  const [cruises, setCruises] = useState<Cruise[]>([])
  const [prices, setPrices] = useState<PricetableItem[]>([])
  const [bellaPrices, setBellaPrices] = useState<PricetableItem | undefined>()
  const [legendaPrices, setlegendaPrices] = useState<PricetableItem | undefined>()
  const [dinnerPrices, setdinnerPrices] = useState<PricetableItem | undefined>()

  useEffect(() => {
    const cruises = departures ? departureToCruiseConverter(departures) : []
    const pricess = ticketPrices ? ticketPrices : []

    const bellaIdx = prices?.findIndex((item) => item.cruise.internalId === "501")
    setBellaPrices(prices?.[bellaIdx])

    const legendaIdx = prices?.findIndex((item) => item.cruise.internalId === "502")
    setlegendaPrices(prices?.[legendaIdx])

    const dinnerIdx = prices?.findIndex((item) => item.cruise.internalId === "775")
    setdinnerPrices(prices?.[dinnerIdx])

    setCruises(cruises)
    setPrices(pricess)

    return () => {}
  }, [departures, ticketPrices, exchangeRate])

  return (
    <TimetableLayout cruises={cruises} theme={{size: 'NORMAL', coloring: 'NO_FADE_ON_PAST'}}>
      <S.PTableGroupHeading>
        <div>1€ = {exchangeRate} HUF</div>
      </S.PTableGroupHeading>
      <S.PTableGroupWrapper>
        <S.PTableFirstRow>
          <PriceTableByCruise items={converter(bellaPrices)} />
          <PriceTableByCruise items={converter(legendaPrices)} />
        </S.PTableFirstRow>
        <PriceTableByCruise items={converter(dinnerPrices)} />
      </S.PTableGroupWrapper>
      <div
        className={classNames({
          hasActuality: !!actuality,
        })}
      >
        {actuality ? (
          <>
            <S.PTableVideoPlayer url={vid2} width={476} height={255} />
            <S.PTableMergedVideo url={hangulatVid} width={470} height={270} />
          </>
        ) : (
          <S.PTableVideoPlayer url={vid2} width={969} height={546} />
        )}
      </div>
      {actuality && (
        <S.ActualityWrapper>
          <ActualityBox isTodayShown={actuality?.isTodayShown} html={actuality?.contentHTML} title={actuality?.title} />
        </S.ActualityWrapper>
      )}
    </TimetableLayout>
  )
}

type ByCruiseProps = {
  items: PriceTableItem[] | undefined
}

const getTags = (item: PriceTableItem) => {
  return {
    isTypeBPCard: item?.type === TicketPriceDTOTypeEnum.BPCARD,
    isTypeAdult: item?.type === TicketPriceDTOTypeEnum.ADULT,
  }
}

const PriceTableByCruise: FC<ByCruiseProps> = ({ items, ...props }) => {
  const isDinner = items?.[0]?.internalCruiseId === "775"
  const cruiseName = items?.[0]?.cruiseName

  const formatName = (str?: string) => {
    let match = str?.match(/^(.*)(\(.*\))/)
    return (
      <>
        {match?.[1] || str} {match?.[2] && <small>{match?.[2]}</small>}
      </>
    )
  }

  return (
    <S.StyledPriceTableByCruise>
      <S.PTableHeader className={classNames({ isDinner: isDinner })}>
        {isDinner ? `${cruiseName} ∙ Vacsorás hajóutak` : cruiseName}
      </S.PTableHeader>
      <S.TicketList className={classNames({ isDinner: isDinner })}>
        {items
          ?.sort((item, next) => item.index - next.index)
          ?.map((ticket, idx) => {
            let { isTypeAdult, isTypeBPCard } = getTags(ticket)
            return (
              <S.Ticket
                key={idx}
                className={classNames({
                  isTypeAdult: isTypeAdult,
                  isTypeBPCard: isTypeBPCard,
                })}
              >
                <S.TicketName>{formatName(ticket.name)}:</S.TicketName>
                <S.TicketPriceEUR>
                  <Currency price={ticket.priceEUR} currency="EUR" />
                </S.TicketPriceEUR>
                <S.TicketPriceHUF>
                  <Currency price={ticket.priceHUF} currency="HUF" />
                </S.TicketPriceHUF>
              </S.Ticket>
            )
          })}
      </S.TicketList>
    </S.StyledPriceTableByCruise>
  )
}

type CurrProps = {
  price: string | undefined
  currency: "HUF" | "EUR"
}

export const StyledCurrency = styled.span`
  font-family: font-condensed-bold;
  & .currency {
    font-size: 0.8em;
    font-family: font-semi-condensed;
  }
`
const Currency = ({ currency, price }: CurrProps) => {
  const isFree = Number(price) === 0

  return (
    <StyledCurrency>
      {isFree ? (
        "FREE"
      ) : (
        <>
          <NumberFormat
            displayType={"text"}
            decimalSeparator="."
            thousandsGroupStyle="thousand"
            thousandSeparator=" "
            fixedDecimalScale={false}
            value={price}
          />{" "}
          <span className="currency">{currency}</span>
        </>
      )}
    </StyledCurrency>
  )
}

export default RakpartPromo_V5View
