import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import { format, parseISO } from "date-fns"
import React from "react"
import { AppConfig } from "../../config/AppConfig"
import { useApplicationState } from "../../context/AppContext"
import { DisplayApi } from "../../service/DisplayApiService"
import styl from "./splash.module.css"

type Props = {
  customMacAddress?: string
}

const SplashView: React.FC<Props> = ({ customMacAddress, ...props }) => {
  const state = useApplicationState()
  const displayApi = DisplayApi
  const updatedTime = state.serverConfig.data?.serverTime
  const isDev = state.serverConfig.data?.environment === "DEV"
  const serverIP = () => process.env.REACT_APP_API_HOST || "n/a"

  const formatDate = (value: string) => {
    return format(parseISO(value), "yyyy.MM.dd. HH:mm:ss")
  }

  return (
    <div className={styl.mainWrapper}>
      <div className={styl.screenId}>
        <div className={styl.isLoading}>
          {state.serverConfig.data?.serverTime !== undefined ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <LoadingOutlined style={{ color: "red" }} />
          )}
        </div>
        <div>{customMacAddress || displayApi?.deviceId()}</div>
      </div>
      <div className={styl.footer}>
        <div className={styl.status}>
          <Tag color={updatedTime ? "green" : "red"} style={{}}>
            {(updatedTime && "ONLINE") || "OFFLINE"}
          </Tag>
          <span className={styl.serverTime}>
            Server ({serverIP()}) time: {(updatedTime && formatDate(updatedTime)) || "n/a"}
          </span>
        </div>
        <div>
          {isDev && <Tag color={"#f60"}>DEVELOPMENT</Tag>} ver {AppConfig.version}
        </div>
      </div>
    </div>
  )
}

export default SplashView
