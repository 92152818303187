/* eslint-disable jsx-a11y/alt-text */
import { DepartureModel, LocalTime, RunningContentContentTypeEnum, WeatherDataDTO } from "@hmedia/legenda-ds-api-client"
import { format, parseISO } from "date-fns"
import React, { FC, useEffect, useState } from "react"
import { AnimatedArrow } from "../../component/ArrowRight/AnimatedArrow"
import { Celsius } from "../../component/Celsius/Celsius"
import { CurrentTime } from "../../component/CurrentTime/CurrentTime"
import { DirectionArrows } from "../../component/DirectionArrows/DirectionArrows"
import { VideoPlayer } from "../../component/VideoPlayer/VideoPlayer"
import { WeatherIcon } from "../../component/Weather/WeatherIcon"
import { AppConfig } from "../../config/AppConfig"
import { useApplicationState } from "../../context/AppContext"
import { formatCelsius } from "../../utils/utility"
import styl from "./style.module.css"
import * as S from "./styles"

export type Cruise = {
  time: string
  name: string
  extraInfo?: string
}

type Props = {
  type?: RunningContentContentTypeEnum
}

const KulsoVaroView: FC<Props> = ({ type, ...props }) => {
  const state = useApplicationState()

  const videoURL = `${AppConfig.api.contentDir}/kulsovaro/Kulsovaro_V5.mp4`
  const bgUrl = `${AppConfig.api.contentDir}/penztar/penztar-bg-empty.jpg`
  const [updatedTime, setUpdatedTime] = useState("")
  const [updatedWeather, setUpdatedWeather] = useState<WeatherDataDTO>()
  const [nextDepartures1, setnextDepartures1] = useState<DepartureModel>()
  const [nextDepartures2, setnextDepartures2] = useState<DepartureModel>()

  const celsius = formatCelsius(updatedWeather?.temp)
  const formatDate = (value: string) => format(parseISO(value), "dd. MM. yyyy.")
  const getDay = (value: string) => format(parseISO(value), "iiii")
  const formatHourMinutes = (value?: LocalTime) => (value as string)?.slice(0, 5)

  useEffect(() => {
    setUpdatedTime(state.serverConfig.data?.serverTime)
    setUpdatedWeather(state.serverConfig.data?.weatherDataDTO)

    const nextDepartures = state.serverConfig.data?.content?.kulsoVaroInstance?.nextDepartures
    setnextDepartures1(nextDepartures?.["GATE1"])
    setnextDepartures2(nextDepartures?.["GATE2"])

    return () => {}
  }, [
    state.serverConfig.data?.serverTime,
    state.serverConfig.data?.weatherDataDTO,
    state.serverConfig.data?.content?.kulsoVaroInstance,
  ])

  return (
    <div className={styl.mainWrapper} style={{ backgroundImage: `url(${bgUrl})` }}>
      <div className={styl.infobar}>
        <div className={styl.dateTimePanel}>
          <div className={styl.time}>
            <CurrentTime />
          </div>
          <div className={styl.date}>
            <div className={styl.dateYMD}>{updatedTime && formatDate(updatedTime)}</div>
          </div>
        </div>
        <div className={styl.nextPanel}>
          <div className={styl.nextTitle}>NEXT DEPARTURES / KÖVETKEZŐ INDULÁSOK:</div>
          <div className={styl.nextGateRow}>
            <div className={styl.nextArrow}>
              <AnimatedArrow />
            </div>
            <div className={styl.nextGate}>GATE 1:</div>
            <div className={styl.nextStartTime}>{formatHourMinutes(nextDepartures1?.startAt)}</div>
            <div className={styl.nextName}>{nextDepartures1?.cruise?.displayNameMap?.["en"]}</div>
          </div>
          <div className={styl.nextGateRow}>
            <div className={styl.nextArrow}>
              <AnimatedArrow />
            </div>
            <div className={styl.nextGate}>GATE 2:</div>
            <div className={styl.nextStartTime}>{formatHourMinutes(nextDepartures2?.startAt)}</div>
            <div className={styl.nextName}>{nextDepartures2?.cruise?.displayNameMap?.["en"]}</div>
          </div>
        </div>
        <div className={styl.weatherPanel}>
          <div className={styl.weatherIcon}>
            <WeatherIcon code={updatedWeather?.iconCode} day={updatedWeather?.isDay} />
          </div>
          <div className={styl.weatherCelsius}>
            {celsius}
            <sup>
              <Celsius />
            </sup>
          </div>
        </div>
      </div>
      <S.AnimVideoWrapper>
        <S.AnimWrapper>
          {type === RunningContentContentTypeEnum.KULSO_VARO1 && (
            <S.ArrowsWrapper>
              <S.Gate1Text>GATE 2</S.Gate1Text>
              <DirectionArrows
                style={{
                  position: "absolute",
                  top: "500px",
                  left: "220px",
                  transform: "scale3d(3.5, 2.5, 1) rotateZ(90deg)",
                }}
              />
              <S.Gate2Text>GATE 1</S.Gate2Text>
              <DirectionArrows
                style={{
                  position: "absolute",
                  bottom: "190px",
                  left: "210px",
                  transform: "scale3d(2.5, 3.5, 1) rotateZ(180deg)",
                }}
              />
            </S.ArrowsWrapper>
          )}
          {type === RunningContentContentTypeEnum.KULSO_VARO2 && (
            <S.ArrowsWrapper>
              <S.Gate1Text>GATE 1</S.Gate1Text>
              <DirectionArrows
                style={{
                  position: "absolute",
                  top: "500px",
                  left: "230px",
                  transform: "scale3d(3.5, 2.5, 1) rotateZ(90deg)",
                }}
              />
              <S.Gate2Text>GATE 2</S.Gate2Text>
              <DirectionArrows
                style={{
                  position: "absolute",
                  bottom: "190px",
                  left: "220px",
                  transform: "scale3d(2.5, 3.5, 1) rotateZ(0deg)",
                }}
              />
            </S.ArrowsWrapper>
          )}
        </S.AnimWrapper>
        <S.VideoWrapper>
          <VideoPlayer url={videoURL} width={1431} />
        </S.VideoWrapper>
      </S.AnimVideoWrapper>
    </div>
  )
}

export default KulsoVaroView
