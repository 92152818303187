import React, { FC } from "react"
import ReactPlayer from "react-player/lazy"

type Props = {
  url?: string
  width?: number
  height?: number
  onProgress?: (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => void
}

// const defUrl = "http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_1080p_30fps_normal.mp4"

export const VideoPlayer: FC<Props> = ({ url, width, height, onProgress, ...props }: Props) => (
  <div {...props}>
    <ReactPlayer
      width={`${width}px`}
      height={`${height}px`}
      url={url}
      playing={true}
      muted={true}
      loop={true}    
      onProgress={onProgress}
    />
  </div>
)
