import classNames from "classnames"
import { FC, HTMLAttributes } from "react"
import styled, { keyframes } from "styled-components"

type Props = {
  direction?: "left" | "right" | "up"
  arrowSize?: number
  numOfArrows?: number
} & HTMLAttributes<HTMLDivElement>

export const ArrowContainer = styled.div<Props>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.up {
    transform: rotateZ(90deg);
  }
  &.right {
    transform: rotateZ(180deg);
  }
  transform: scale3d(3, 3, 1) rotateZ(-45deg);
`

const slide = keyframes`
    0% { opacity:0; transform: translateX(75px); }	
   20% { opacity:1; transform: translateX(45px); }	
   80% { opacity:1; transform: translateX(-45px); }	
  100% { opacity:0; transform: translateX(-75px); }	
`

export const Sliding = styled.div<Props>`
  position: absolute;
  -webkit-animation: ${slide} 6s linear infinite;
  animation: ${slide} ${(p) => `${p.numOfArrows}s`} linear infinite;

  /* &.delay1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  &.delay2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  &.delay3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }
  &.delay4 {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }
  &.delay5 {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  } */
`

export const Arrow = styled.div`
  width: 30px;
  height: 30px;
  border: 8px solid;
  border-color: white transparent transparent white;
  transform: rotate(-45deg);
`

export const DirectionArrows: FC<Props> = ({ direction = "up", arrowSize, numOfArrows = 6, ...props }) => {
  return (
    <ArrowContainer
      {...props}
      className={classNames({
        left: direction === "left",
        right: direction === "right",
        up: direction === "up",
      })}
    >
      <Sliding>
        <Arrow />
      </Sliding>
      {[...Array(numOfArrows)].map((number, index) => {
        return (
          <Sliding
            className={`delay${index + 1}`}
            style={{
              animationDelay: `${index + 1}s`,
            }}
          >
            <Arrow />
          </Sliding>
        )
      })}
      {/* <Sliding className="delay1">
        <Arrow />
      </Sliding>
      <Sliding className="delay2">
        <Arrow />
      </Sliding>
      <Sliding className="delay3">
        <Arrow />
      </Sliding>
      <Sliding className="delay4">
        <Arrow />
      </Sliding>
      <Sliding className="delay5">
        <Arrow />
      </Sliding> */}
    </ArrowContainer>
  )
}
