import React from "react"
import { forwardRef, ReactNode } from "react"
import styled, { css, keyframes } from "styled-components"
import { AppConfig } from "../../config/AppConfig"
import { IsCancelledIcon, IsSoldOutIcon } from "./icons"
import minta from "/mnt/DATA/develop/legenda-digital-signage/legenda-ds-tizen-client/src/contents/RakpartPromo/RAKPART_V5_1_minta.png"

export const reddit = css`
  /* box-shadow: inset 0 0 0px 1px #ff0000; */
`

type Propps = {
  children: ReactNode
} & { ref: HTMLDivElement }

type InnerProps = {
  scale?: number
} & Propps

const OuterContainer = forwardRef<HTMLDivElement, Propps>(({ children, ...otherProps }, ref) => {
  return (
    <div {...otherProps} ref={ref}>
      {children}
    </div>
  )
})

const InnerWrapper = forwardRef<HTMLDivElement, InnerProps>(({ children, ...otherProps }, ref) => {
  return (
    <div {...otherProps} ref={ref}>
      {children}
    </div>
  )
})

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: #ffffff;
  overflow: hidden;
  background-image: url("${AppConfig.api.contentDir}/penztar/penztar-bg-empty.jpg") !important;
  /* background-color: rgb(0 0 0 / 75%);
  background-blend-mode: color-burn; */

  &.themeCompact {
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/rakpart__new-bg.png") !important;
  }
  &.themeCompact.themeCompactV2 {
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/v2/rakpart__bg_v2.jpg") !important;
  }
  &.themeCompact.themeCompactV3 {
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/v3/rakpart__new-bg-v3.jpg") !important;
  }
  &.themeCompact.themeCompactV4 {
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/v4/rakpart__new-bg-v4.jpg") !important;
  }
`

export const RightSideContainer = styled(OuterContainer)`
  ${reddit}
  position: absolute;
  right: 55px;
  top: 145px;
  width: 785px;
  bottom: 40px;

  .themeCompact & {
    right: 55px;
    top: 185px;
    width: 570px;
    bottom: 117px;
  }

  .themeCompactV3 &,
  .themeCompactV4 & {
    right: 55px;
    top: 185px;
    width: 570px;
    bottom: 360px;
  }
`

export const InnerContainer = styled(InnerWrapper)`
  display: flex;
  flex-direction: column;
  transform: ${(props) => `scale3d(${props.scale}, ${props.scale}, 1)`};
  transform-origin: top left;
  width: 100%;
`

export const TodaysDepartureHeading = styled.div`
  position: absolute;
  left: 1079px;
  top: 56px;
  width: 786px;
  height: 72px;
  background-image: url("${AppConfig.api.contentDir}/penztar/heading-todays.jpg") !important;

  .themeCompact & {
    left: 1297px;
    top: 113px;
    width: 565px;
    height: 61px;
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/rakpart__TODAYS__heading--en.png") !important;
  }
  .coloringNoFadeOnPast & {
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/v5/rakpart__timeetable__heading__V5.png") !important;
  }
`

const pulse = keyframes`
  0% { opacity: 0 }
  45% { opacity: 0 }
  50% { opacity: 1 }
  95% { opacity: 1 }
  100% { opacity: 0 }
`

export const TodaysDepartureHeadingHU = styled(TodaysDepartureHeading)`
  background-image: url("${AppConfig.api.contentDir}/penztar/heading-todays-hu.jpg") !important;
  animation: ${pulse} 10s infinite;

  .themeCompact & {
    background-image: url("${AppConfig.api.contentDir}/rakpart-promo/rakpart__TODAYS__heading--hu.png") !important;
  }
`
export const GateName = styled.div`
  font-family: font-semi-condensed-bold;
  font-size: 20px;
  text-align: right;
  padding: 0px 7px 0px 7px;
  background-color: #69a3d3;
  color: black !important;
  line-height: 26px;
  margin-left: 10px;
  align-self: baseline;
  white-space: nowrap;
  margin-top: 6px;

  .themeCompact & {
    margin-right: 8px;
    font-size: 16px;
    padding: 2px 6px 0px 6px;
    line-height: 22px;
  }
`

export const DepartureRow = styled.div`
  ${reddit}
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: left;
  font-size: 36px;
  color: #ffffff;
  padding-left: 50px;
  line-height: 48px;
  font-family: font-semi-condensed;

  &.isNext {
  }
  &.isGone {
    div {
      color: rgba(255, 255, 255, 0.1);
      font-size: 34px;
    }
    ${GateName} {
    }
  }
  &:not(.isGone) {
    &.isSoldOut div {
      color: #f65f64;
    }
    &.isCancelled div {
      color: grey;
      text-decoration: 1px line-through white;
    }
  }

  .themeCompact & {
    padding-left: 24px;
    font-size: 31px;
    line-height: 44px;

    &.isGone {
      div {
        font-size: 28px;
      }
    }
  }
`
export const StartingDepartureArrow = styled.div`
  margin-right: 20px;
  margin-left: -44px;

  .themeCompact & {
    margin-right: 0px;
    margin-left: -14px;
    margin-top: -3px;

    & > span {
      padding: 5px !important;
      border-width: 0px 4px 4px 0px !important;
    }
  }
`

export const CruiseNameRow = styled.div`
  ${reddit}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const StartTime = styled.div`
  font-family: font-bold;
  width: 89px;
  min-width: 89px;
  text-align: right;
  padding-top: 0px;
`
export const Name = styled.div`
  ${reddit}
  margin-left: 12px;
  text-transform: uppercase;
  padding-top: 6px;
  line-height: 1em;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
  .themeCompact & {
    margin-bottom: 6px;
  }
`
export const CancelledIconSVG = styled(IsCancelledIcon)`
  margin-left: auto;
  margin-top: 3px;

  .themeCompact & svg {
    margin-left: 3px;
  }
`
export const SoldOutIconSvg = styled(IsSoldOutIcon)`
  margin-left: auto;
  margin-top: 3px;
`

export const ExtraInfo = styled.div`
  color: #69a3d3;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 7px;
  margin-top: -6px;
  padding-left: 100px;
`
