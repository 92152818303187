import { DepartureModel } from "@hmedia/legenda-ds-api-client"
import React, { useEffect, useState } from "react"
import { AppConfig } from "../../config/AppConfig"
import { useApplicationState } from "../../context/AppContext"
import styl from "./style.module.css"
import { ReactComponent as ArrowIcon } from "./dock8-arrow.svg"

export type Cruise = {
  time: string
  name: string
  extraInfo?: string
}

const BeszallohidDock8View = () => {
  const state = useApplicationState()

  const bgImg = `${AppConfig.api.contentDir}/beszallohid/beszallohid-dock8-bg.jpg`
  const [nextDeparture, setnextDeparture] = useState<DepartureModel>()

  useEffect(() => {
    const nextDeparture = state.serverConfig.data?.content?.felsoTeraszInstance?.nextDepartures?.[0]
    setnextDeparture(nextDeparture)

    return () => {}
  }, [
    state.serverConfig.data?.serverTime,
    state.serverConfig.data?.weatherDataDTO,
    state.serverConfig.data?.content?.felsoTeraszInstance,
  ])

  return (
    <div className={styl.mainWrapper} style={{ backgroundImage: `url(${bgImg})` }}>
      <div className={styl.dock8Arrow}>
        <ArrowIcon />
      </div>
      <div className={styl.nextWrapper}>
        <div className={styl.titleNext}>NEXT&nbsp;</div>
        <div className={styl.titleNextDep}> DEPARTURE:&nbsp;</div>
        <div className={styl.time}>{(nextDeparture?.startAt as string)?.slice(0, 5)}</div>
      </div>
    </div>
  )
}

export default BeszallohidDock8View
