import { DepartureModel, DepartureModelGateEnum } from '@hmedia/legenda-ds-api-client';
import { Cruise } from '../component/TimetableLayout/TimetableLayout';
export declare type IAsyncState<A> = {
  data?: A,
  loading: boolean,
  error?: string
}


// ACTION CREATORS

export type Action<TPayload> = {
  type: string;
  payload: TPayload;
}

export interface IActionCreator<P> {
  type: string;

  (payload: P): Action<P>;
}

function actionCreator<P>(type: string): IActionCreator<P> {
  return Object.assign(
    (payload: P) => ({ type, payload }),
    { type }
  );
}

function isActionType<P>(action: Action<any>,
  actionCreator: IActionCreator<P>): action is Action<P> {
  return action.type === actionCreator.type;
}

export { actionCreator, isActionType }

export const departureToCruiseConverter = (dep?: DepartureModel[] | []): Cruise[] =>
  dep?.map((dep?: DepartureModel) => ({
    name: dep?.cruise?.displayNameMap["en"],
    time: (dep?.startAt as string).slice(0, 5),
    isCancelled: dep?.isCancelled,
    isGone: dep?.isKifutott,
    isNext: dep?.isSoronKovetkezo,
    isSoldOut: dep?.isFull,
    extraInfo: dep?.description,
    gate: dep?.gate ? gateEnumToString(dep?.gate) : ''
  })) as Cruise[]

export const gateEnumToString = (gate: DepartureModelGateEnum): string => {
  switch (gate) {
    case DepartureModelGateEnum.GATE1:
      return 'GATE 1'
    case DepartureModelGateEnum.GATE2:
      return 'GATE 2'
  }
}