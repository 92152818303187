import styled from "styled-components/macro"
import { AppConfig } from "../../config/AppConfig"

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  background-color: #0b101d;
  color: #ffffff;
  overflow: hidden;
  background-image: url("${AppConfig.api.contentDir}/penztar/penztar-bg-empty.jpg") !important;

`

export const GateDateContainer = styled.div`
  border-right: 1px solid white;
`

export const GateBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1050px;
  min-width: 1050px;

  font-size: 14rem;
  font-family: font-bold;
  line-height: 12rem;
  padding-top: 45px;
  background-color: #69a3d3;
  color: black;
  white-space: nowrap;
`

export const NextCruiseContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 3.5rem;
  padding-top: 3rem;
  font-family: font-bold;
  overflow: hidden;
`

export const CruiseStartTime = styled.div`
  margin-right: 1.5rem;
  color: #69a3d3;
  font-family: font-semi-condensed-bold;
`
// ∘ U+2218
export const NextCruiseRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 4.7rem;
  line-height: 1;
  height: 4.7rem;
  margin-top: 1rem;

  &:nth-of-type(1n + 4) {
    display: none;
  }
  &.isNext {
    font-size: 6.7rem;
    margin-bottom: 2rem;

    div {
      font-family: font-semi-condensed;
    }

    ${CruiseStartTime} {
      font-family: font-semi-condensed-bold;
    }
  }

  &:not(.isNext) {
    opacity: 0.4;
    padding-left: 9.1rem;
  }
`
export const TimeNameWrapper = styled.div`
  display: flex;
  &.isNext {
    border: 1px solid white;
    padding: 16px 10px 3px 10px;
    border-radius: 5px;
  }
`
export const StyledArrow = styled.div`
  margin-right: 1rem;
`

export const CruiseName = styled.div`
  font-family: font-semi-condensed;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 670px;
  white-space: nowrap;
`
