import styled from "styled-components/macro"

export const Celsius = styled.span`
  display: inline-block;
  background-color: rgba(0, 0, 0, 0);
  width: 18px;
  height: 18px;
  border: 4px solid aliceblue;
  border-radius: 17px;
`
