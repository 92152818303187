import { MediaFileDTO, MediaFileDTOFileTypeEnum } from "@hmedia/legenda-ds-api-client"
import React, { CSSProperties } from "react"
import ReactPlayer from "react-player"
import { useApplicationState } from "../../context/AppContext"

interface Props {}

const MediaFileView: React.FC<Props> = ({ ...props }) => {
  const state = useApplicationState()
  const media = state.serverConfig.data?.content?.mediaFileInstance

  const fullscreenStyle: CSSProperties = {
    backgroundColor: "black",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  }

  return (
    <>
      {media && (
        <>
          {media?.["filetype"] === MediaFileDTOFileTypeEnum.IMAGE && (
            <img style={fullscreenStyle} src={media.fileURL} width={"100vw"} height={"100vh"} alt="" />
          )}
          {media?.["filetype"] === MediaFileDTOFileTypeEnum.VIDEO && (
            <ReactPlayer
              style={fullscreenStyle}
              url={media.fileURL}
              width={"100vw"}
              height={"100vh"}
              playing={true}
              muted={true}
            ></ReactPlayer>
          )}
        </>
      )}
    </>
  )
}

export default MediaFileView
